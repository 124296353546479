import React from "react";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { 
  BorderDiv,
  HeaderLogo,
  HeaderH1,
  HeaderH3,
  SectionH2,
  SectionH4,
  SectionP,
  StyledAccordion,
  UnderlinedAnchor,
} from "../helpers/styledComponents";
import { ButtonLink } from "../components/Buttons";
import styled from "styled-components";
import timeToVoteLogo from "../../static/images/time-to-vote-logo.png";

// The accordion panels.
const panels = [
  {
    key: 'panel-0',
    title: 'What is happening to Time to Vote?',
    content: {
      content: (
        <div>
          <SectionP>
          Time to Vote is becoming a signature initiative of the Civic Alliance. The Civic Alliance not only supports its member companies in offering time off to vote, it also provides guidance and best practices on corporate civic engagement. 
          </SectionP>
        </div>
      ),
    }
  },
  {
    key: 'panel-1',
    title: 'What is the Civic Alliance?',
    content: {
      content: (
        <div>
          <SectionP>
          Founded in 2020 by <a href="https://www.democracy.works">Democracy Works </a> and the <a href="https://www.caa.com/foundation"> CAA Foundation </a> in partnership with Meteorite, the Civic Alliance is America's premier nonpartisan coalition of more than 1,300 businesses united by a shared commitment to our democracy. Civic Alliance member companies believe a strong democracy is good for business, and they pledge to strengthen our democracy by encouraging civic participation and standing for safe, accessible, and trusted elections.  
          </SectionP>
          <SectionP>
          Companies join the Civic Alliance by making a nonpartisan pledge to encourage their employees and/or customers to vote and be civically active. There is no financial requirement to become a member. In exchange for your commitment to civic engagement,  member companies are provided with research-backed toolkits and programming to help  develop effective civic initiatives. 
          </SectionP>
          <SectionP>
          The Civic Alliance is fiscally housed at Democracy Works, a 501c3 supported by the generosity of corporate and philanthropic <a href="https://www.democracy.works/our-supporters">donors</a>.
          </SectionP>
        </div>
      ),
    },
  },
  {
    key: 'panel-2',
    title: 'Will my Time to Vote membership be automatically transferred to the Civic Alliance?',
    content: {
      content: (
        <div>
          <SectionP>
          Time to Vote member companies will receive outreach from the Time to Vote team about the transition and to affirm their interest in joining the Civic Alliance.
          </SectionP>
        </div>
      ),
    },
  },
  {
    key: 'panel-3',
    title: "I'm unsure if my company can make further civic commitments. Is my company eligible to join the Civic Alliance?",
    content: {
      content: (
        <div>
          <SectionP>
          Civic Alliance members are required to affirm their commitment to the <a href="/" >Civic Alliance Pledge</a>.
          Once you're a member, you can engage with our team and resources to level up your civic impact.
          Offering time off to vote to your employees is an important step, and we look forward to working with Time to Vote members as they consider how to grow their impact.  
          </SectionP>
        </div>
      ),
    },
  },
  {
    key: 'panel-4',
    title: "I think my company is already a Civic Alliance member. What do I need to do?",
    content: {
      content: (
        <div>
          <SectionP>
          Great! You will continue to receive resources and updates from the Civic Alliance. If you need support, please contact <a href="mailto:concierge@civicalliance.com">concierge@civicalliance.com</a>. 
          </SectionP>
        </div>
      ),
    },
  },
  {
    key: 'panel-5',
    title: "I have more questions. Can I speak with someone?",
    content: {
      content: (
        <div>
          <SectionP>
           Absolutely. Send us an email at <a href="mailto:join@civicalliance.com">join@civicalliance.com</a> to meet with our team. 
          </SectionP>
        </div>
      ),
    },
  },
];

interface HeaderProps {
  className?: string;
}

const StyledP = styled.p`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
`;
// Start Header Content.
const HeaderContent = ({ className }:HeaderProps) => {
  return(
    <>
      <span className={`${className} img`}>
        <HeaderLogo src={timeToVoteLogo}/>
      </span>
      {/* <img className={`${className} img`} loading="lazy" alt="Time to vote" src={timeToVoteLogo} /> */}
      <div className={`${className} content`}>
        <HeaderH1>Amplifying Corporate Civic Impact</HeaderH1>
        <HeaderH3>The nonpartisan movement to make time off to vote a standard business practice is joining the Civic Alliance </HeaderH3>
        <StyledP>
          To deepen nonpartisan corporate civic engagement in 2024 and beyond, Time to Vote is becoming a signature initiative of the Civic Alliance.
        </StyledP>
        <StyledP>
          Time to Vote members are invited to build on their commitment to ensure employees have a work schedule that allows them time to vote in U.S. elections and join the Civic Alliance.
        </StyledP>
      </div>
    </>
  );
};

const Styled = styled(HeaderContent)`
  &.img {
    margin-left: 2rem;
  }
  &.content {
    max-width: 800px;
    margin: 0 auto;
  }
`;

const StyledHeaderContent = () => <Styled />;
// End Header Content.

const StyledList = styled.ul`
  li > a {
    color: white;
    &:hover {
      color: #f9f9f9;
    }
  }
`;

export default function TimeToVote() {
  return (
    <Layout
      mode="light"
      caaBannerContent={ undefined }
      headerContent={ StyledHeaderContent() }
      title="Civic Alliance"
    >
      {/* IMPACT */}
      <Section mode="dark" larger>
        <SectionH2>Time to Vote's Groundbreaking Impact</SectionH2>
        <StyledP>
          One of the most common reasons people give for not voting is that they're too busy with the demands of work and life. To address this barrier and increase voter participation, a diverse coalition of companies came together in 2018 to launch Time to Vote. The companies made a commitment to ensure that their employees had a work schedule that allowed them time to vote in that year's midterm elections.
        </StyledP>
        <StyledP>
          Since its inception, <UnderlinedAnchor mode='light' href="https://www.maketimetovote.org" target='_blank' >Time to Vote</UnderlinedAnchor> has grown to over 2,000 member companies and elevated the national conversation on voter access.
        </StyledP>
        <BorderDiv mode="dark">
          <SectionH4> In the news:</SectionH4>
          <StyledList>
            <li><a target="_blank" href="https://www.nytimes.com/2020/06/12/business/for-racial-justice-employees-need-paid-hours-off-for-voting.html"> For Racial Justice, Employees Need Time Off For Voting </a></li>
            <li><a target="_blank" href="https://www.wsj.com/articles/companies-make-push-to-give-workers-time-to-vote-11582119001"> Companies Make Push To Give Workers Time To Vote </a></li>
            <li><a target="_blank" href="https://news.bloomberglaw.com/daily-labor-report/more-businesses-pledge-time-off-to-vote-as-leave-laws-stagnate"> More Businesses Pledge Time Off To Vote As Leave Laws Stagnate </a></li>
          </StyledList>
        </BorderDiv>
      </Section>
      {/* WELCOME */}
      <Section mode="light" larger>
        <SectionH2>Welcoming Time to Vote Members to the Civic Alliance </SectionH2>
        <SectionP>
          Ensuring employees have time to vote is now the norm—not the exception—thanks in large part to the leadership of Time to Vote companies. This presents an exciting opportunity to build on this culture shift by further expanding voter access and participation among your employees and deepening corporate civic engagement.
        </SectionP>
        <SectionP>
          The Civic Alliance is America's premier nonpartisan coalition of more than 1,300 businesses united by a shared commitment to our democracy. Civic Alliance member companies believe a strong democracy is good for business, and they pledge to strengthen our democracy by encouraging civic participation and standing for safe, accessible, and trusted elections.
        </SectionP>
        <SectionP>
          The Civic Alliance supports its member companies by providing complimentary membership, including tools, training, and events to inspire their employees and customers to engage in civic life. Civic Alliance member companies have impactful civic programs, which can include creating a time off to vote policy, educating employees and customers on how to vote, encouraging employees and customers to support elections by serving as poll workers, aligning company values with civic norms, and more.
        </SectionP>
        <ButtonLink center={true} mode="dark" to="https://cvcallnce.com/join" newTab> Join the Civic Alliance</ButtonLink>
      </Section>
      {/* CONTINUE */}
      <Section mode="dark" larger>
        <SectionH2> Continue Your Civic Journey </SectionH2>
        <StyledP>
        The Civic Alliance is excited to welcome Time to Vote companies to our community.
        Take the pledge and become a member of the Civic Alliance. 
        </StyledP>
        <ButtonLink center={true} mode="light" to="https://cvcallnce.com/join" newTab> Get Started </ButtonLink>
      </Section>
      {/* FAQs */}
      <Section mode="light" larger>
        <SectionH2> Frequently Asked Questions for Time to Vote Members </SectionH2>
        <StyledAccordion defaultActiveIndex={0} panels={panels} />
      </Section>
    </Layout>
  );
};